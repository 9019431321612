import { NavigationState } from '@platform-ui-kit/components-library'
import { WppTopbar, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from 'app/header/Header.module.scss'
import logoUrl from 'assets/logo.svg'

const baseNavigation: NavigationState[] = [
  { label: 'Audiences', value: 'audiences' },
  { label: 'Campaigns', value: '', active: false },
  { label: 'Touchpoints', value: '', active: false },
  { label: 'Budget split', value: '', active: false },
  // { label: 'Navigation and Workspaces', value: 'navigation-workspaces' },
]

export const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { osContext } = useOs()

  const navigation = baseNavigation.map(navItem => ({
    ...navItem,
    // Displayed links will have correct full url
    path: `/${osContext.baseUrl}/${navItem.value}`,
  }))
  const topbarValue = navigation
    .map(navItem => navItem.value)
    .find(navItem => location.pathname.startsWith(`/${navItem}`))

  return (
    <WppTopbar
      className={styles.wppTopBar}
      value={topbarValue}
      navigation={navigation}
      onWppChange={e => navigate(`/${e.detail.value}`)}
    >
      <div slot="app" className={styles.wppTopBarLogo}>
        <img src={logoUrl} alt="logo" />
        <WppTypography className={styles.wppTopBarTitle} type="m-strong" tag="h3">
          Consumer Demo App
        </WppTypography>
      </div>
    </WppTopbar>
  )
}
