export const CONSUMER_APP_ID = '02ff1a88-a969-4718-b2a0-4dcf2d8a2084'
export const CONSUMER_INSTANCE_ID = '0468cd79-e4f1-48e9-86bb-a09a8a1bf265'

export interface ConsumerConnectionInfo {
  connectionId: string
  dataType: string
  version: string
  sourceAppId: string
  sourceAppInstanceId: string
  sourceBaseUrl: string
}

export interface ProducerConnectionInfo {
  connectionId: string
  dataType: string
  version: string
  targetAppId: string
  targetAppInstanceId: string
}

export interface ConnectionsContext {
  asProducer: ProducerConnectionInfo[]
  asConsumer: ConsumerConnectionInfo[]
}

export interface AppInfoContext {
  appName: string
  appId: string
  appInstanceId: string
}

export interface AppContext {
  appName: string
  appId: string
  appInstanceId: string
  preciousBaseUrl: string
  connections: {
    asProducer: ProducerConnectionInfo[]
    asConsumer: ConsumerConnectionInfo[]
  }
}
